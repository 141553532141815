.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.footer {
  margin: 4em;
  float: right;
}
.mainContainer {
  width: 100%;
  margin: auto;
  min-height: 80%;
  .childrenContainer {
    margin: 3em;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 1280px) {
  .mainContainer {
    width: 100%;
    margin: auto;
    min-height: 70%;
    .childrenContainer {
      margin: 3em;
    }
  }
}
@media only screen and (max-width: 850px) {
  .mainContainer {
    width: 100%;
    margin: auto;
    min-height: 70%;
    .childrenContainer {
      margin: 0.5em;
    }
  }
}
