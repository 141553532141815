// Import all mixins and variables.
// (These are also available from '@fluentui/react/dist/sass/References'.)
@import "@fluentui/react/dist/sass/References";
.legendMessage {
  float: right;
}
.legendContainer {
  border: 0px;
}
.errorMessageBanner {
  margin-top: 1em;
}
.error-message {
  animation-name: css-0, css-13;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 0.85em;
  font-weight: 400;
  color: rgb(164, 38, 44);
  margin: 0px;
  padding-top: 5px;
  padding-left: 3em;
  display: flex;
  align-items: center;
}
.betaVersionMessage {
  margin-top: 1em;
  margin-left: 3em;
}
.groupTitle {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-weight: 600;
  color: rgb(50, 49, 48);
  overflow-wrap: break-word;
}
.loadingMessage {
  padding: 2em;
}
.regionCheckbox {
  padding: 0.5em;
  max-width: 35em;
}

.textField-description {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 10px;
  font-weight: 400;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
}
.title {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: rgb(50, 49, 48);
  box-sizing: border-box;
  box-shadow: none;
  margin-right: 17em;
  display: block;
  padding: 5px 0px;
  overflow-wrap: break-word;

  &.resultNotification {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
      -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    box-shadow: none;
    margin-right: 10em;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
  }
}
.recentEntityContainer {
  max-width: 35em;
  padding: 5px;
}
.submitButton {
  margin: 10px;
}
ul {
  list-style-type: none;
}
.url {
  text-overflow: ellipsis;
  overflow: auto;
  white-space: nowrap;
  max-width: 70em;
  min-width: 30em;
}
.scanResultTop {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-size: 0;
}
.sub {
  vertical-align: top;
  display: inline-block;
}
.addIconStacVertical {
  display: flex;
  flex-flow: row-reverse nowrap;
  width: auto;
  height: auto;
  max-width: 500px;
  box-sizing: border-box;
  flex-direction: column-reverse;
}
.addIconStack {
  display: flex;
  flex-flow: row-reverse nowrap;
  width: auto;
  height: auto;
  max-width: 500px;
  box-sizing: border-box;
  flex-direction: row-reverse;

  &.searchBox {
    display: flex;
    padding-top: 3em;
    margin-left: 1em;
    flex-flow: row nowrap;
    width: auto;
    height: auto;
    box-sizing: border-box;
  }
}
.addIconStackItem {
  -webkit-font-smoothing: antialiased;
  flex-basis: auto;
  height: auto;
  width: auto;
  flex-shrink: 0;
  align-self: auto;
  margin-left: 3px;
}
.addIconStackItemLabel {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  flex-basis: auto;
  height: auto;
  width: auto;
  flex-shrink: 0;
  align-self: auto;
  margin-left: 3px;
  margin-top: 6px;
}
.entityContainer {
  @include ms-depth-4; // Using mixins
  background: white;
  padding: 1em;
  margin: 1em;
  max-width: 90%;
  min-width: 33em;
  overflow: auto;
  border: 1px, solid #dcdcdc;
  &.detection {
    padding: 1em;
    margin: 1em;
    max-width: 80em;
    min-width: none;
    content: flex;
  }
  &.owner {
    max-width: 40em;
    min-width: none;
    overflow-wrap: anywhere;
  }
  &.resultNotification {
    max-width: 40em;
    min-width: none;
    overflow-wrap: anywhere;
  }
  &.urls {
    max-width: 80%;
    min-width: none;
    overflow-wrap: normal;
  }
  &.scanResult {
    overflow-wrap: anywhere;
    max-width: 90%;
    min-width: none;
  }
  .downloadSarifLogButton {
    float: right;
  }
  .detailContainer {
    padding: 20em;
    margin: 1em;
  }
  .entitiyGroup {
    display: inline;
    justify-content: start;
  }
  .entity {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 0.3em;
    border: 1px solid white;
    grid-template-areas: "left right";
    justify-content: start;
    padding: 0.3em;

    .left {
      grid-column-start: 1;
      border-radius: 25px;

      &.indented {
        padding-left: 4em;
      }
    }

    .right {
      grid-column-start: 2;
      overflow-wrap: anywhere;
      border-radius: 25px;
    }

    .entityDetailButton {
      display: inline-block;
    }
  }
}

@media only screen and (orientation: portrait) {
  .url {
    text-overflow: ellipsis;
    overflow: clip;
    white-space: nowrap;
    max-width: 50em;
    min-width: none;
  }
  .entityContainer {
    @include ms-depth-4;
    background: white;
    padding: 1em;
    padding-left: 1em;
    padding-right: 1em;
    margin: 0px;
    max-width: 30em;
    min-width: 10em;
    overflow: auto;
    border: 1px, solid #dcdcdc;

    &.finding {
      max-width: 100%;
      min-width: none;
      margin: 1em;
      overflow-wrap: anywhere;
    }
    &.scanDefinitionDetail {
      max-width: 100%;
      min-width: none;
    }
    &.resultNotification {
      max-width: 40em;
      min-width: none;
      overflow-wrap: anywhere;
    }
    &.scanDefinition {
      overflow-wrap: anywhere;
      max-width: 80%;
      min-width: none;
    }
    &.scanResult {
      overflow-wrap: anywhere;
      max-width: 100%;
      min-width: none;
    }
    &.scanRequestDetails {
      overflow-wrap: anywhere;
      max-width: 80%;
      min-width: none;
    }
    .downloadSarifLogButton {
      float: right;
    }
    .detailContainer {
      @include ms-depth-4;
      background: white;
      padding: 1em;
      margin: 1em;
      max-width: 90%;
      min-width: 10em;
      overflow: auto;
    }
  }
}

@media only screen and (max-width: 850px) {
  .entityContainer {
    @include ms-depth-4;
    padding: 0.5em;
    margin: 0.5em;
    max-width: 30em;
    min-width: 10em;
    overflow: auto;

    &.finding {
      max-width: 100%;
      min-width: none;
      margin: 1em;
      overflow-wrap: anywhere;
    }
    &.scanDefinitionDetail {
      max-width: 100%;
      min-width: none;
    }
    &.resultNotification {
      max-width: 40em;
      min-width: none;
      overflow-wrap: anywhere;
    }
    &.scanDefinition {
      overflow-wrap: anywhere;
      max-width: 80%;
      min-width: none;
    }
    &.scanResult {
      overflow-wrap: anywhere;
      max-width: 100%;
      min-width: none;
    }
    &.scanRequestDetails {
      overflow-wrap: anywhere;
      max-width: 80%;
      min-width: none;
    }
    .downloadSarifLogButton {
      float: right;
    }
    .detailContainer {
      @include ms-depth-4;
      background: white;
      padding: 1em;
      margin: 1em;
      max-width: 90%;
      min-width: 10em;
      overflow: auto;
    }
    .entity {
      display: list-item;
      border: 1px solid;
      padding: 1em;
      border-color: white;

      &.owner {
        max-width: 30%;
        min-width: none;
        overflow-wrap: anywhere;
      }
      &.scanRequestDetails {
        margin: 1em;
        overflow-wrap: anywhere;
      }

      .left {
        border-radius: 25px;
        &.indented {
          padding-left: 4em;
        }
      }

      .right {
        border-radius: 25px;
      }

      .entityDetailButton {
        display: inline-block;
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .addIconStack {
    display: flex;
    flex-flow: row-reverse nowrap;
    width: auto;
    height: auto;
    max-width: 500px;
    box-sizing: border-box;
    flex-direction: column;
  }
  .addIconStackItem {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
      -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    flex-basis: auto;
    height: auto;
    width: auto;
    flex-shrink: 0;
    align-self: auto;
  }
  .entityContainer {
    @include ms-depth-4;
    padding: 0.5em;
    margin: 0.5em;
    max-width: 30em;
    min-width: 10em;
    overflow: auto;
    border: 1px, solid;
    .entity {
      display: list-item;
      border: 1px solid #dcdcdc;
      padding: 1em;
      border-color: white;

      &.owner {
        max-width: 30%;
        min-width: none;
        overflow-wrap: anywhere;
      }
      &.scanRequestDetails {
        margin: 1em;
        overflow-wrap: anywhere;
      }

      .left {
        border-radius: 25px;
        &.indented {
          padding-left: 4em;
        }
      }

      .right {
        border-radius: 25px;
      }

      .entityDetailButton {
        display: inline-block;
      }
    }
  }
}
