.newBanner {
  padding-top: 1em;
  padding-left: 0.5em;
  width:80px;
  height: 40px;
}
.welcomeMessage {
  text-align: center;
  padding: 1em;
}

.wrapper {
  margin: auto;
}

.docs-wrapper {
  margin: 4%;
}

.stackContainer {
  display: flex;
  flex-flow: row nowrap;
  flex-direction: row;
  flex-wrap: nowrap;
  width: auto;
  height: auto;
  box-sizing: border-box;
  justify-content: space-evenly;
}
.stackItem {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  flex-basis: auto;
  height: auto;
  width: auto;
  flex-shrink: 1;
}

@media only screen and (max-width: 850px) {
  .stackContainer {
    display: flex;
    flex-flow: column nowrap;
    flex-direction: column;
    flex-wrap: nowrap;
    width: auto;
    height: auto;
    box-sizing: border-box;
    justify-content: space-evenly;
  }
  .stackItem {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    flex-basis: auto;
    height: auto;
    width: auto;
    margin-top: 5em;
    flex-shrink: 1;
  }
}
